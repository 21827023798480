import React from "react"
import "./index.scss"
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav,NavDropdown } from "react-bootstrap";
import timely from "../images/timely.svg";
import hug from "../images/hug.png";
import it from "../images/it.png";
import linkedin from "../images/linkedin.png";
import mail from "../images/mail.png";
import strong from "../images/strong.png";
import time from "../images/time.svg";
import voice from "../images/voice.png";
import wave from "../images/wave.png";
import brain from "../images/brain.svg";
import arrow from "../images/arrow.svg";
import trophy from "../images/trophy.svg";
import dieu from "../images/moi.jpg";






const IndexPage = () => (
    <div>
        <Navbar bg="white" fixed="top" expand="lg" >
            <div className="container">
                <Navbar.Brand href="#">
                    <img src={dieu} className="logo"/>
                    Guillaume Tallandier
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="navRest">

                        <Nav.Link href="/timely" className="btnNav">
                            Timely
                            <img src={timely} />
                        </Nav.Link>
                        <Nav.Link href="https://linkedin.com/in/guillaume-tallandier" className="btnNav">
                                Linkedin
                        </Nav.Link>
                        <Nav.Link href="https://github.com/guillaumetallandier" className="btnNav">
                                GitHub
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
        <div className="container">
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div className="title">
                        <h1 className="descTitle">Bonjour</h1>
                        <img src={wave} className="imgTitle"/>
                    </div>
                    <a href="mailto:tallandier.guillaume@gmail.com" >
                        <div className="lienMail">
                            Dire bonjour
                     </div>
                    </a>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div className="marginTop presentation">

                        <img src={it} className="imgTitle"/>
                        <h4 className="titlePic"> Présentation </h4>
                    </div>
                    <div className="presentationTexte">
                        Je suis Guillaume Tallandier, étudiant en DUT informatique à Illkirch.
                        Je travaille principalement dans le domaine du web et du développement d'applications mobiles.
                    </div>
                </div>
                <div className="col-md-6 offset-md-3">
                    <div className="marginTop presentation">
                        <img src={strong} className="imgTitle"/>
                        <h4 className="titlePic"> Passions </h4>
                    </div>
                    <div className="presentationTexte">
                        Je suis passionné par le développement web et le WebDesign. J'aime résoudre des problèmes via des applications web ou mobiles. J'apprécie particulièrement les défis et le travail en équipe
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-3 offset-md-3">
                    <div className="marginTop competences">
                        <div className="competenceTitle">
                            <img src={voice} className="imgTitle"/>
                            <h4 className="titlePic"> Mes compétences </h4>
                        </div>
                        <div className="competence">
                            <div className="pourcent">
                                <div className="pourcentIn" style={{width:"70px"}}>
                                </div>
                            </div>
                            <div className="competenceTxt">
                                React
                            </div>
                        </div>
                        <div className="competence">
                            <div className="pourcent">
                                <div className="pourcentIn" style={{width:"50px"}}>
                                </div>
                            </div>
                            <div className="competenceTxt">
                                Express
                            </div>
                        </div>
                        <div className="competence">
                            <div className="pourcent">
                                <div className="pourcentIn" style={{width:"55px"}}>
                                </div>
                            </div>
                            <div className="competenceTxt">
                                Laravel
                            </div>
                        </div>
                        <div  className="competence">
                            <div className="pourcent">
                                <div className="pourcentIn" style={{width:"70px"}} >
                                </div>
                            </div>
                            <div className="competenceTxt">
                                Android
                            </div>
                        </div>


                    </div>
                </div>
                <div className="col-md-3 offset-md-0">
                    <div className="marginTop competences">
                        <div className="competenceTitle">
                            <img src={trophy} className="imgTitle"/>
                            <h4 className="titlePic"> Mes motivations </h4>
                        </div>
                        <div className="competence">
                            <div className="pourcent">
                                <div className="pourcentIn" style={{width:"70px"}}>
                                </div>
                            </div>
                            <div className="competenceTxt">
                                Travail d'équipe
                            </div>
                        </div>
                        <div className="competence">
                            <div className="pourcent">
                                <div className="pourcentIn" style={{width:"65px"}}>
                                </div>
                            </div>
                            <div className="competenceTxt">
                                Méthode Agile
                            </div>
                        </div>
                        <div className="competence">
                            <div className="pourcent">
                                <div className="pourcentIn" style={{width:"80px"}}>
                                </div>
                            </div>
                            <div className="competenceTxt" >
                                Happiness
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div className="marginTop projets">
                        <div className="projetTitle">
                            <img src={it} className="imgTitle"/>
                            <h4 className="titlePic"> Mes projets </h4>
                        </div>
                        <div className="projet">
                            <img src={timely} className="imgproj" />
                            <div className="descProj">
                                <h5> Timely</h5>
                                <div>
                                    Application Android donnant les horaires de passages
                                    des prochains bus/trams pour chaque arrêt de Strasbourg.
                                </div>
                                <div>
                                    Technologies utilisées : Java (Android)
                                </div>

                                <div className="lien">
                                    <a className="lienTimely" href="/timely">
                                        En savoir plus
                                        <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.96 3.81C5.9362 3.74863 5.90052 3.69255 5.855 3.645L3.355 1.145C3.30838 1.09838 3.25304 1.0614 3.19212 1.03617C3.13121 1.01094 3.06593 0.997955 3 0.997955C2.86685 0.997955 2.73915 1.05085 2.645 1.145C2.59838 1.19162 2.5614 1.24697 2.53617 1.30788C2.51094 1.36879 2.49795 1.43407 2.49795 1.5C2.49795 1.63315 2.55085 1.76085 2.645 1.855L4.295 3.5H0.5C0.367392 3.5 0.240215 3.55268 0.146447 3.64645C0.0526785 3.74022 0 3.86739 0 4C0 4.13261 0.0526785 4.25979 0.146447 4.35355C0.240215 4.44732 0.367392 4.5 0.5 4.5H4.295L2.645 6.145C2.59814 6.19148 2.56094 6.24678 2.53555 6.30771C2.51017 6.36864 2.4971 6.434 2.4971 6.5C2.4971 6.56601 2.51017 6.63136 2.53555 6.69229C2.56094 6.75322 2.59814 6.80852 2.645 6.855C2.69148 6.90186 2.74678 6.93906 2.80771 6.96445C2.86864 6.98983 2.93399 7.0029 3 7.0029C3.06601 7.0029 3.13136 6.98983 3.19229 6.96445C3.25322 6.93906 3.30852 6.90186 3.355 6.855L5.855 4.355C5.90052 4.30745 5.9362 4.25138 5.96 4.19C6.01001 4.06827 6.01001 3.93173 5.96 3.81Z" fill="#C8C6C6"/>
                                        </svg>
                                    </a>

                                </div>
                            </div>
                        </div>

                        <div className="projet">
                            <img src={time} className="imgproj"/>
                            <div className="descProj">
                                <h5> List Manager</h5>
                                <div>
                                    Application web permettant à des utilisateurs de faire
                                    des listes de tâches.
                                </div>
                                <div>
                                    Technologies utilisées : React, Laravel
                                </div>
                            </div>
                        </div>

                        <div className="projet">
                            <img src={brain} className="imgproj"/>
                            <div className="descProj">
                                <h5> Wall-e</h5>
                                <div>
                                    Projet ayant pour but de mettre en application les lois d'Asimov.
                                    Un robot réagit à différentes situations en fonction des lois ajoutées pour le joueur.
                                </div>
                                <div>
                                    Technologies utilisées : Unity (C#)
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div className="marginTop contactDiv">
                        <div className="contactTitle">
                            <img src={hug} className="imgTitle"/>
                            <h4 className="titlePic"> Restons en contact </h4>
                        </div>
                        <div className="txtContact presentationTexte">
                        Vous voulez en savoir plus? Discutons ensemble et voyons comment nous pouvons travailler ensemble !
                        Je suis disponible par mail ou sur Linkidin.
                        </div>
                        <div className="" style={{textAlign:"center"}}>
                            <a href="https://linkedin.com/in/guillaume-tallandier" className="logoContact">
                                <img src={linkedin}/>
                                Linkedin
                            </a>
                            <a href="mailto:tallandier.guillaume@gmail.com" className="logoContact">
                                <img src={mail}/>
                                Mail
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default IndexPage
